<template>
    <AModal
        :visible="visible"
        title="Permission Menu"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true">
        <MdTable
            :data-source="state.data.current"
            :columns="state.columns"
            row-key="id"
            :loading="state.isLoading">
            <template #no="{ index }">
                <span>
                    {{ 0 + index }}
                </span>
            </template>
            <template #tipe="{ text }">
                <span>
                    {{ text !== null ? 'Sub Menu' : 'Menu Utama' }}
                </span>
            </template>
            <template #menu_utama="{ text }">
                <span>
                    {{ text !== null ? state.data.menu.find(i => i.id === text).name : '' }}
                </span>
            </template>
            <template #assigned="{ record }">
                <span>
                    <ACheckbox
                        v-model:checked="record.assigned"
                        @change="event => checkChange(event, record)"
                    />
                </span>
            </template>
        </MdTable>

        <template #footer>
            <AButton
                key="submit"
                @click="handleSubmit"
                :loading="state.isSubmit"
                type="primary">Simpan</AButton>
            <AButton
                key="cancel"
                @click="handleModalCancel">Batal</AButton>
        </template>
    </AModal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
} from 'vue'
import { message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import _ from 'lodash'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            columns: [
                {
                    title: 'Deskripsi Menu',
                    dataIndex: 'name',
                },
                {
                    title: 'Assign',
                    dataIndex: 'assigned',
                    slots: { customRender: 'assigned' },
                },
            ],
            endpoint: {
                current: `/api/users/permission-menu/${props.item.id}`,
                menu: `/api/roles/${props.item.role_id}?expand=menus`,
            },
            isLoading: false,
            isSubmit: false,
            data: {
                current: [],
                permission: [],
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const fetchMenuData = () => {
            state.isLoading = true

            Promise.all([
                apiClient.get(state.endpoint.current), // Fetch menu by user ID
                apiClient.get(state.endpoint.menu),   // Fetch menu by role ID
            ])
            .then(([userResponse, roleResponse]) => {
                // Process response from fetchMenuByUserId
                state.data.permission = _.map(userResponse.data, ({ menu_id, permission_type }) => ({
                    menu_id,
                    permission_type,
                }))

                // Process response from fetchMenuByRoleId
                state.data.current = updateAssigned(roleResponse.data?.menus, userResponse.data)
            })
            .finally(() => {
                state.isLoading = false
            })
        }

        const handleSubmit = () => {
            state.isSubmit = true

            apiClient.post(state.endpoint.current, {
                    access: state.data.permission,
                })
                .then(({ data }) => {
                    message.success('Permission menu berhasil diperbarui.')

                    handleModalCancel()
                })
                .finally(() => {
                    state.isSubmit = false
                })
        }

        const checkChange = (e, record) => {
            // Cek apakah record.id sudah ada di data permission
            const existingData = _.find(state.data.permission, { menu_id: record.id })

            if (existingData) {
                // Jika ada, ubah permission_type menjadi 2
                existingData.permission_type = 2
            } else {
                // Jika tidak ada, tambahkan ke data permission dengan permission_type = 1
                state.data.permission.push({ menu_id: record.id, permission_type: 1 })
            }
        }

        // Fungsi rekursif untuk memperbarui `assigned` berdasarkan data validasi
        const updateAssigned = (menu, validations) => {
            if (_.isArray(menu)) {
                // Iterasi untuk array
                return menu.map((item) => updateAssigned(item, validations))
            }

            // Cari validasi untuk menu ini
            const validation = _.find(validations, { menu_id: menu.id })

            // Perbarui `assigned` jika validasi ditemukan
            if (validation) {
                menu.assigned = validation.permission_type === 1
            }

            // Proses child jika ada
            if (menu.children) {
                menu.children = updateAssigned(menu.children, validations)

                // Perbarui parent `assigned` berdasarkan child
                menu.assigned = _.some(menu.children, { assigned: true })
            }

            return menu
        }

        // handle func vue
        onMounted(() => {
            fetchMenuData()
        })

        return {
            state,
            handleModalCancel,
            handleSubmit,
            checkChange,
        }
    },
})
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/form.scss';
</style>
