<template>
    <a-modal
        :visible="visible"
        title="Detail Regional dan Area - Kelola Pengguna"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="60%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- no pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="No Pegawai"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="nopeg"
                        has-feedback>
                        <AInput
                            placeholder="no pegawai"
                            disabled
                            v-model:value="state.data.nopeg" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- nama pegawai -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Nama Pengguna"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }"
                        required
                        name="fullname"
                        has-feedback>
                        <AInput
                            placeholder="nama pengguna"
                            disabled
                            v-model:value="state.data.fullname" />
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- role -->
            <ARow class="form-row">
                <ACol :md="12" :sm="24">
                    <AFormItem
                        label="Role"
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="role_id"
                        has-feedback>
                        <FilterRole
                            style="width:100%"
                            disabled
                            v-model:value="state.data.role_id"
                            show-search
                            :mode="null"/>
                    </AFormItem>
                </ACol>
                <ACol :md="12" :sm="24" v-if="includes([1, 1259963, 1259969], state.data.role_id)">
                    <AFormItem
                        label=""
                        :label-col="{ sm: { span: 8 } }"
                        :wrapper-col="{ sm: { span: 24 - 8 } }"
                        required
                        name="vendor_id"
                        has-feedback>
                        <FilterDistributorAll
                            v-if="state.data.role_id == 1"
                            style="width:100%"
                            disabled
                            v-model:value="state.data.vendor_id"
                            show-search/>
                        <FilterDistributor
                            v-else
                            style="width:100%"
                            disabled
                            v-model:value="state.data.vendor_id"
                            show-search/>
                    </AFormItem>
                </ACol>
            </ARow>

            <!-- regional -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Regional"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }">
                        {{ state.data.region }}
                    </a-form-item>
                </a-col>
            </a-row>

            <!-- area -->
            <a-row class="form-row">
                <a-col :sm="24">
                    <a-form-item
                        label="Area"
                        :label-col="{ sm: { span: 4 } }"
                        :wrapper-col="{ sm: { span: 24 - 4 } }">
                        {{ state.data.areas }}
                    </a-form-item>
                </a-col>
            </a-row>

        </AForm>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import apiClient from '@/services/axios'
import FilterDistributorAll from '@/components/filter/FilterDistributorAll'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRole from '@/components/filter/FilterRole'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        FilterDistributorAll,
        FilterDistributor,
        FilterRole,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['handleOkConversionPoint', 'update:visible'],
    setup(props, { emit }) {
        const state = reactive({
            data: {
                nopeg: null,
                fullname: null,
                role_id: [],
                group_role_id: [],
                kabupaten: [],
                vendor_id: [],
                region: '',
                areas: '',
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        // handle func vue
        onMounted(() => {
            state.data.nopeg = props.item.nopeg
            state.data.fullname = props.item.fullname
            state.data.role_id = props.item.role_id
            state.data.vendor_id = props.item.vendor_id || []
            state.data.group_role_id = props.item.group_role_id
            state.data.kabupaten = props.item.kabupaten != null ? props.item.kabupaten.map(item => item.id) : []

            if (props.item.group_role_id) {
                apiClient
                    .get(`/api/group-roles/${props.item.group_role_id}?expand=group_role_areas`)
                    .then(({ data }) => {
                        state.data.region = data.name
                        state.data.areas = _.orderBy(data.group_role_areas.map(a => a.area_name)).join(', ')
                    })
            }
        })

        return {
            state,
            handleModalCancel,
            // lodash
            includes,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
